import { Email, isEmail, isTelephone, Telephone } from "../../DataType/Informations";

export interface Benevole {
  [key: string]: string | number | Date | boolean;
  CODE: number;
  NOM: string;
  PRENOM: string;
  EMAIL: Email;
  TELEPHONE: Telephone;
  ETAT: string;
  COMMENTAIRE: string;
  DATEDERNIERMAIL: Date;
  FLAGMODIF: boolean;
  IDENTIFIANT: string;
  ADMIN: boolean;
}


export function isBenevoleData(data: unknown): data is Benevole {
  console.log("isBenevoleData - webClients")
  console.log(data);

  if (typeof data != 'object') {console.warn("isBenevoleData ? data n'est pas un objet : ", typeof data); return false;} 
  if (data === null) {console.warn("isBenevoleData ? data est null"); return false;} 

  if ('NOM' in data == false) {console.warn("isBenevoleData ? NOM est absent"); return false;} 
  if (typeof data.NOM != 'string') {console.warn("isBenevoleData ? NOM n'est pas un string : ", typeof data.NOM); return false;} 

  if ('PRENOM' in data == false) {console.warn("isBenevoleData ? PRENOM est absent"); return false;} 
  if (typeof data.PRENOM != 'string') {console.warn("isBenevoleData ? PRENOM n'est pas un string : ", typeof data.PRENOM); return false;} 

  if ('EMAIL' in data == false) {console.warn("isBenevoleData ? EMAIL est absent"); return false;} 
  if (typeof data.EMAIL != 'string') {console.warn("isBenevoleData ? EMAIL n'est pas un string : ", typeof data.EMAIL); return false;} 
  if (isEmail(data.EMAIL) == false) {console.warn("isBenevoleData ? EMAIL n'est pas correct"); return false;} 

  if ('TELEPHONE' in data == false) {console.warn("isBenevoleData ? TELEPHONE est absent"); return false;} 
  if (typeof data.TELEPHONE != 'string') {console.warn("isBenevoleData ? TELEPHONE n'est pas un string : ", typeof data.TELEPHONE); return false;} 
  if (isTelephone(data.TELEPHONE) == false) {console.warn("isBenevoleData ? TELEPHONE n'est pas correct"); return false;} 

  if ('ETAT' in data == false) {console.warn("isBenevoleData ? ETAT est absent"); return false;} 
  if (typeof data.ETAT != 'string') {console.warn("isBenevoleData ? ETAT n'est pas un string : ", typeof data.ETAT); return false;} 

  if ('COMMENTAIRE' in data == false) {console.warn("isBenevoleData ? COMMENTAIRE est absent"); return false;} 
  if (typeof data.COMMENTAIRE != 'string') {console.warn("isBenevoleData ? COMMENTAIRE n'est pas un string : ", typeof data.COMMENTAIRE); return false;}  
  
  if ('DATEDERNIERMAIL' in data == false) {console.warn("isBenevoleData ? DATEDERNIERMAIL est absent"); return false;} 
  if (typeof data.DATEDERNIERMAIL != 'object' && typeof data.DATEDERNIERMAIL != 'string') {console.warn("isBenevoleData ? DATEDERNIERMAIL n'est ni une string, ni un objet : ", typeof data.DATEDERNIERMAIL); return false;} 
  if (typeof data.DATEDERNIERMAIL == 'string' && isNaN(new Date(data.DATEDERNIERMAIL).getTime())) {console.warn("isBenevoleData ? DATEDERNIERMAIL n'est pas une date correcte"); return false;}

  if ('FLAGMODIF' in data == false) {console.warn("isBenevoleData ? FLAGMODIF est absent"); return false;} 
  if (typeof data.FLAGMODIF != 'boolean' && typeof data.FLAGMODIF != 'number') {console.warn("isBenevoleData ? FLAGMODIF n'est pas un booleen ni un nombre : ", typeof data.FLAGMODIF); return false;} 

  if ('IDENTIFIANT' in data == false) {console.warn("isBenevoleData ? IDENTIFIANT est absent"); return false;} 
  if (typeof data.IDENTIFIANT != 'string') {console.warn("isBenevoleData ? IDENTIFIANT n'est pas un string : ", typeof data.IDENTIFIANT); return false;} 

  if ('ADMIN' in data == false) {console.warn("isBenevoleData ? ADMIN est absent"); return false;} 
  if (typeof data.ADMIN != 'boolean' && typeof data.ADMIN != 'number') {console.warn("isBenevoleData ? ADMIN n'est pas un booleen ni un nombre : ", typeof data.ADMIN); return false;} 

  return true;

}

export interface BenevoleReduc {
  CODE: number;
  NOM: string;
  PRENOM: string;
  STATUS_INSCRIPTION: string;
}


export function isBenevoleReducData(data: unknown): data is BenevoleReduc {
  console.log("isBenevoleReducData");
  console.log(data);

  if (typeof data != 'object') {console.warn("isBenevoleData ? data n'est pas un objet : ", typeof data); return false;} 
  if (data === null) {console.warn("isBenevoleData ? data est null"); return false;} 
  
  if ('NOM' in data == false) {console.warn("isBenevoleData ? NOM est absent"); return false;} 
  if (typeof data.NOM != 'string') {console.warn("isBenevoleData ? NOM n'est pas un string : ", typeof data.NOM); return false;} 

  if ('PRENOM' in data == false) {console.warn("isBenevoleData ? PRENOM est absent"); return false;} 
  if (typeof data.PRENOM != 'string') {console.warn("isBenevoleData ? PRENOM n'est pas un string : ", typeof data.PRENOM); return false;} 

  return true;
}

export function isBenevoleReducDataArray(data: unknown): data is BenevoleReduc[] {
  if (!Array.isArray(data)) return false;
  
  for (const item of data) {
    if (!isBenevoleReducData(item)) return false;
  }
  
  return true;
}

export interface BenevoleReferent {
  CODE: number;
  NOM: string;
  PRENOM: string;
  TELEPHONE: Telephone;
  EMAIL: Email;
}

export function isBenevoleReferentData(data: unknown): data is BenevoleReferent {
  console.log("isBenevoleReferentData")
  console.log(data)

  if (typeof data != 'object') {console.warn("isBenevoleData ? data n'est pas un objet : ", typeof data); return false;} 
  if (data === null) {console.warn("isBenevoleData ? data est null"); return false;} 
  
  if ('NOM' in data == false) {console.warn("isBenevoleData ? NOM est absent"); return false;} 
  if (typeof data.NOM != 'string') {console.warn("isBenevoleData ? NOM n'est pas un string : ", typeof data.NOM); return false;} 

  if ('PRENOM' in data == false) {console.warn("isBenevoleData ? PRENOM est absent"); return false;} 
  if (typeof data.PRENOM != 'string') {console.warn("isBenevoleData ? PRENOM n'est pas un string : ", typeof data.PRENOM); return false;} 

  if ('EMAIL' in data == false) {console.warn("isBenevoleData ? EMAIL est absent"); return false;} 
  if (typeof data.EMAIL != 'string') {console.warn("isBenevoleData ? EMAIL n'est pas un string : ", typeof data.EMAIL); return false;} 
  if (isEmail(data.EMAIL) == false) {console.warn("isBenevoleData ? EMAIL n'est pas correct"); return false;} 

  if ('TELEPHONE' in data == false) {console.warn("isBenevoleData ? TELEPHONE est absent"); return false;} 
  if (typeof data.TELEPHONE != 'string') {console.warn("isBenevoleData ? TELEPHONE n'est pas un string : ", typeof data.TELEPHONE); return false;} 
  if (isTelephone(data.TELEPHONE) == false) {console.warn("isBenevoleData ? TELEPHONE n'est pas correct"); return false;} 

  return true;
}


